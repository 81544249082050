import React from "react";
//import rightImg from "images/st/imgTop2.png";
import livePuck1 from "images/st/puckLive2.png";
import livePuck2 from "images/st/puckLive1.png";
import livePuck5 from "images/st/puckLive6.png";
import promo1 from "images/st/promoMain1.png";
import promo2 from "images/st/promo2bmain.png";
import promo3 from "images/st/promoMain2.png";
import promo4 from "images/st/promoMain3.png";
import promo5 from "images/products/mainP1img.png";
import promo6 from "images/g33b.png";
import line1 from "images/st/line1.svg";
import { Helmet } from "react-helmet";
import SectionHero from "containers/PageAbout/SectionHero";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Carousel } from "flowbite-react";
import PageSubcription from "containers/PageSubcription/PageSubcription";


function PageHomeSoon() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Free Energy - CEON</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">

        <div className={`nc-SectionHero relative`} data-nc-id="SectionHero" >
          <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
            <div className="flex-1 w-120 lg:w-64">
              <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
              Uw energie kosten <span className="headingHigh1">transparant</span> en <span className="headingHigh1">eerlijk</span>
              <img src={line1} alt="" className="cs-screen"></img>
              </h1>
              <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 pb-6">
              Wij helpen u om uw echte energiekosten transparant en eerlijk in te zien. Wij zijn geen energieleverancier. Wij zijn onafhankelijk en niet verbonden aan een energiepartij. Hierdoor kunnen we u het beste advies en inzicht geven over en in uw verbruik. Dit alles voor maar klein bedrag per maand:
              </span>
              <ButtonPrimary href="/checkout">Bestellen</ButtonPrimary>
            </div>
            <div className="flex-1 w-120 lg:w-64">
              <img className="w-full rounded-lg" src={promo5} alt="" />
            </div>
          </div>
      </div>

      <div className={`nc-SectionHero relative py-6 lg:py-20`} data-nc-id="SectionHero" >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center relative text-center lg:text-left">
             <div className="">
              <img className="w-full rounded-2xl" src={promo2} alt="" />
            </div>
            <div className="">
              <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 headingHigh2Con">
              Huidige energiecontract meest <span className="headingHigh2">passend</span> voor u?
              </h2>
              <span className="block py-5 text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
              Dit is moeilijk te bepalen. Omdat wij dit echter zo transparant mogelijk voor u maken, wordt het makkelijker voor u om uw werkelijke verbruik en kosten te vergelijken met de diverse aanbieders. Dit zorgt ervoor dat u in de toekomst meer kunt besparen. Heeft u bijvoorbeeld zonnepanelen en een dynamisch contract dan zijn de zomer maanden waarschijnlijk niet aantrekkelijk voor uw. Met Free Energy app kan u dit makkelijk inzien wat het beste voor u is.
              </span>
              
            </div>
          </div>
      </div>


<div className="container py-6 lg:py-15 space-y-16 lg:space-y-28">

<div className={`nc-SectionHero relative py-6 lg:py-18`} data-nc-id="SectionHero" >
  <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
    <div className="flex-1 w-120 lg:w-64">
      <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
      Vast<span className="headingHigh1">?</span> Variabel<span className="headingHigh1">?</span> Dynamisch<span className="headingHigh1">?</span>
      <img src={line1} alt="" className="cs-screen"></img>
      </h2>
      <span className="block py-5 text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 pb-6">
      Welk energiecontract past het beste bij u? Is het dynamisch of juist niet omdat u bijvoorbeeld zonnepanelen heeft. Met Free Energie kunt u precies zien wat voor u het voordeligste is gezien u verbruik.
      </span>
    </div>
    <div className="flex-1 w-120 lg:w-64">
      <img className="w-full rounded-lg" src={promo4} alt="" />
    </div>
  </div>
</div>
</div>


<div className={`nc-SectionHero relative`} data-nc-id="SectionHero" >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center relative text-center lg:text-left">
             <div className="">
              <img className="w-full rounded-2xl" src={promo3} alt="" />
            </div>
            <div className="">
              <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 headingHigh2Con">
              Beter <span className="headingHigh2">inzicht</span> in u verbruikt
              </h1>
              <span className="block py-5 text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
              Door het gebruik van de Free Energy App heeft u altijd inzicht en overzicht van uw historische gegevens en instellingen. Met onze geadviseerdfe dashboard krijgt u de juiste informatie om te kunnen besparen. Zelfs als u overstapt naar een nieuwe energieleverancier blijven deze gegevens behouden. Een overstap wordt daardor dus een stuk makkelijker.
              </span>
              
            </div>
          </div>
      </div>

      <div className={`nc-SectionHero relative`} data-nc-id="SectionHero" >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center relative text-center lg:text-left">

            <div className="">
              <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 headingHigh2Con">
              <span className="headingHigh2">Controleer</span> u facturen
              </h1>
              <span className="block py-5 text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                Maandelijkse facturen of jaar nota's kunnen onduidelijk zijn en wellicht fouten bevatten door u leverancier. Controleer eenvoudig u factuur of hij wel echt klopt. 
              </span>
              
            </div>  
    <div className="">
      <img className="w-full rounded-2xl" src={promo6} alt="" />
    </div>
          </div>
      </div>

<div className={`nc-SectionHero relative`} data-nc-id="SectionHero" >
          <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
            <div className="w-screen space-y-5 lg:space-y-7 flex-grow">
              <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 headingHigh2Con">
              De <span className="headingHigh2">Puck</span>
              </h1>
              <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
              Om de gegevens van uw slimme meter te krijgen is een apparaatje nodig. Dit apparaatje noemen wij de Puck (omdat hij op een ijshockeypuck lijkt). Dit apparaatje is makkelijk aan te sluiten en krijgt u gratis bij uw Free Energy Abonnement.
              </span>
              
            </div>
            <div className="flex flex-col carouselImg">
            <Carousel className="carouselImg" indicators={false} slideInterval={5000} leftControl={" "} rightControl={" "}  >
  <img className="rounded-2xl"
    alt="Puck" width="270"
    src={livePuck1}
  />
  <img className="rounded-2xl"
    alt="Puck" width="270"
    src={livePuck2}
  />
  <img className="rounded-2xl"
    alt="Puck" width="270"
    src={livePuck5}
  />

</Carousel>


            </div>
          </div>
      </div>

      <PageSubcription></PageSubcription>

<SectionHero
          rightImg={promo1}
          heading="Apps"
          btnText=""
          right={false}
          appbadges={true}
          subHeading="Gebuik uw telefoon, tablet of laptop/PC/Mac om altijd en overal op de hoogter te zijn. Download de app direct via: "
  />


<AccordionInfo data={[{
      name: "Wat heb ik nodig",
      open: false,
      content:
        'Een smille meter en een telefoon met bluetooth',
    },{
      name: "Wat gebeurt er met mijn gegeven.",
      open: false,
      content:
        'We gaan uiterst zorgvuldig met u gegevens om. Alle data van uw verbruik worden annoniem separaat opgeslagen. We gegeven geen andere partijen toegang of verkopen geen enkele gegevens door.',
    },{
      name: "Krijg ik een klein apparaatje (Puck)",
      open: false,
      content:
        'Ja na bestelling sturen we u een Puck die u gemakkelijk aansluit op uw smartmeter.',
    },{
      name: "Welke abonementen zijn er?",
      open: false,
      content:
        'Wij hebben 1 abonementvorm en dat is per jaar. Wel kan er per maand opgezegd worden voor de maand erop. Resteerende bedrag wordt dan terug betaald.',
    },{
      name: "Hoe zeg ik op?",
      open: false,
      content:
        'U kunt per maand opzeggen met ingang van de maand daarop. Resteerende bedrag wordt dan terug betaald.',
    },{
      name: "Onafhankelijke advies orgaan",
      open: false,
      content:
        'Je praktische gids voor iedere duurzame stap: <a href="https://www.milieucentraal.nl/" />Milieu Centraal</a>',
    }]} />

      {/*<SectionHero
          rightImg={rightImg}
          heading="👋 Hallo"
          btnText=""
          right={false}
          subHeading="Wij geloven in een mooie toekomst waarin energie efficiënt en duurzaam geproduceerd en gebruikt wordt. Niet alleen door gebruik te maken van duurzame bronnen als de wind en de zon maar ook door betere inzichten in het verbruik. Daarom willen we je graag helpen met deze energie transitie. Ontdek hier de meest innovatieve producten om efficient met energie om te gaan!"
  />*/}



    </div>

    </div>
  );
}

export default PageHomeSoon;
