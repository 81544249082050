import React, { useEffect } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_SOON } from "data/navigation";
import { useLocation } from "react-router-dom";

function Navigation() {
  const location = useLocation();

  useEffect(() => {
    //$('.sub-menu').addClass("invisible");
    /*setTimeout(() => {
      $('.sub-menu').removeClass("visible");
    }, 1000);*/
  }, [location.pathname]);

  return (
    <ul className="nc-Navigation flex items-center">
      {NAVIGATION_SOON.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
