//import rightImg from "images/45002_C.jpg";
import rightImg2 from "images/st/imgTop2.png";
import React, { FC } from "react";
//import SectionFounder from "./SectionFounder";
//import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
//import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
//import SectionClientSay from "components/SectionClientSay/SectionClientSay";
//import SectionPromo3 from "components/SectionPromo3";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Over Free Energy</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg2}
          right={true}
          heading="👋 Hallo"
          btnText=""
          subHeading="Leuk dat je er bent. Wij delen graag onze passie en hopelijk is dit ook jou passie. En dat is een toekomst waar energie duurzaam en goedkoop is. Stel je voor als al onze electriciteit wordt opgewekt door duurzame bronnen en deze in overvloed zijn. Dan zal de wereld en de mensheid er veel beter voorstaan dan nu. Geen oorlogen meer om of door geld en macht van of door fossiele brandstoffen. Onze lucht zal veel schoner zijn. Mileu en klimaat zullen niet hoeven te lijken onder onze verbruik van energie. etc. etc. Voor alles wat we doen is energie nodig. Wat zal het daarom mooi zijn voor de toekomst als iedereen hier genoeg van heeft om aan zijn eigen verbruik te voldoen en nog veel meer om bijvoorbeeld zijn of haar energie zoveel mogelijk te kunnen gebruiken om anderen en maatschappij te verbeteren. Om aan deze visie een steentje bij te dragen willen we handvatten bieden om voor iedereen makkelijker aan deze doelen te kunnen werken."
        />
        <p>
        Voor meer informatie horen we graag van u via onze <a href="/contact">contact pagina</a> of de chat rechts onder aan het scherm.
        </p>

        <div className={`nc-SectionPromo2`}>
      <div className="relative flex flex-col lg:flex-row lg:justify-end bg-yellow-100 dark:bg-slate-800 rounded-2xl sm:rounded-[40px] p-4 pb-0 sm:p-5 sm:pb-0 lg:p-8">
      <div className="z-max">Wij doen ons best om een steentje bij te dragen aan de VN Duurzame Ontwikkelingsdoelstellingen. En dan voornamelijk doel 7 betaalbare en duurzame energie. Zie onze webshop catalogus voor producten die op verschillenbde manieren met dit doel te maken heeft. 
        </div>
        <svg className="u-isHidden"><symbol viewBox="0 0 186 186" id="hero-7-turbine-blades"><g fill="none" fill-rule="evenodd"><path d="M.5.5h185v185H.5z"></path><path fill="#FFF" d="M89.652 89.958l-1.158-33.02 1.214-52.876 10.328 69.652-3.97 13.88-2.076 4.852m-.063 4.98L65.91 114.94l-46.4 25.386 55.157-43.77 14.006-3.502 5.24-.63m4.345-2.434l29.174 15.506 45.186 27.49-65.484-25.88-10.036-10.38-3.165-4.223"></path><circle fill="#FFF" cx="93" cy="93" r="7"></circle></g></symbol><symbol viewBox="0 0 18 232" id="hero-7-turbine-pole"><path d="M.948 231.688L5.903 3.244l6.192-2.48 4.95 230.924" fill="#FFF" fill-rule="evenodd"></path></symbol></svg>
        <svg viewBox="0 0 186 325" width="186" height="325" className="Hero7-energyTurbine"><g transform="translate(0 -68)"><use className="Hero7-energyBlades" xlinkHref="#hero-7-turbine-blades"></use></g><use y="95" height="232" xlinkHref="#hero-7-turbine-pole"></use></svg>
        </div>
      </div>

        {/*<SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

  <SectionPromo3 />*/}
      </div>
    </div>
  );
};

export default PageAbout;
