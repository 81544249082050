import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export interface PageContactProps {
  className?: string;
}

const CheckoutConfirmPage: FC<PageContactProps> = ({ className = "" }) => {
  
  const { orderNr } = useParams<{ orderNr: string }>();

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Bestelling</title>
      </Helmet>
      <div className="">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Bestelling  is verwerkt.
        </h2>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative">
          <div className="marginP">
          <p>Bedrankt voor u bestelling. Uw bestelnummer is {orderNr}. Wij zullen uw zo snel mogelijk uw Free Energy Monitor toesturen!<br />
<br />
</p></div>

    </div>  
      </div>
    </div>
  );
};

export default CheckoutConfirmPage;
