import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { cartItems, Product } from "data/data";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { Fragment, useEffect, useState } from "react";
//import ContactInfo from "./ContactInfo";
//import PaymentMethod from "./PaymentMethod";
//import ShippingAddress from "./ShippingAddress";
import React from "react";
import Select from "shared/Select/Select";
import CheckboxVoor from "shared/Checkbox/CheckboxVoor";
import Radio from "shared/Radio/Radio";
import { useForm, SubmitHandler } from "react-hook-form";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'


import freePNG from "images/products/freeenergy.png";

//import { stringify } from "querystring";

type Inputs = {
  phone: string;
  email_id: string;
  first_name: string;
  last_name: string;
  address_line1:string;
  address_line2:string;
  pincode:string;
  city:string;
  country:string;
  issuer:string;
  paymenttype:string;
};

const smartmeters = [
  { id:1, name: 'Geen Idee (Maakt ook niet uit!)' },
  { id:3, name: 'Actaris' },
  { id:4, name: 'Landis+Gyr E350' },
  { id:5, name: 'Kamstrup Multical 601' },
  { id:6, name: 'Iskra ME382' },
  { id:7, name: 'Iskra ME371' },
  { id:8, name: 'Iskra SMR 5.0 ED' },
  { id:9, name: 'Kaifa MA105' },
  { id:10, name: 'Kaifa MA304' },
  { id:11, name: 'Itron Centron C1SR' },
  { id:12, name: 'Sagemcom T211' },
  { id:13, name: 'Elster AS3000' },
  { id:14, name: 'ABB B21' },
  { id:15, name: 'Janitza UMG 604' },
  { id:16, name: 'ZIV Z400' },
  { id:2, name: 'Mijn type slimme meter staat niet in deze lijst' },
  
]


export default function CheckoutPage() {
//const CheckoutPage = () => {
//class CheckoutPage extends React.Component<{}, {
//  labelvalue: string, items: any, DetailsLoaded:Boolean, tabActive: any, 
//  phone: string; email_id: string;first_name:string;last_name:string;address_line1:string;address_line2:string;pincode:string;city:string;country:string;}>{
  
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = data => console.log(data);

  const [smartmeterSel, setSmartmeterSel] = useState(smartmeters[0])

  const [postnlFails, setPostnlFails] = useState<any>(false);

  const [cart, setCart] = useState<any>({
    items: { items: [], totalPrice: 0.00, discountText:'',discountCode:''},
    phone: '', email_id: '', first_name:'',last_name:'',address_line1:'',address_line2:'',pincode:'',city:'',country:'',
    paymenttype: 'ideal', issuer: '',
    labelvalue: '',
    DetailsLoaded: false,
    selecteerBankError: false,
    tabActive: 'ContactInfo'
}); 

  const [items, setItems] = useState<cartItems>({items:[], totaalKaal:0, shipping:0, btw:0, totalPrice:0, subtotaal:0, discountText:'',discountCode:''})
  useEffect(() => {
    fetch("/api/shop/cart")
     .then(resp => resp.json())
     .then(data => setItems(data))
  },[])

  const applyDiscount = (event:any) => {
    const elDis:any = document.getElementById('discountCode');
    
    fetch('/api/shop/addDiscount', {
      method: 'PATCH',
      body: JSON.stringify({
        "code": elDis?.value
    }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((res) => res.json())
    .then((json) => {
      fetch("/api/shop/cart")
        .then(resp => resp.json())
        .then(data => setItems(data))
    })
  }

const checkPostCode = () => {
  if (cart.pincode.length > 5 && cart.address_line2.length > 0) {
    cart.pincode = cart.pincode.replace(/ /g,'');

    if(postnlFails === false){
      fetch('/api/shop/postcodecheck', {
        method: 'POST',
        body: JSON.stringify({
          "postcode": cart.pincode,
          "huisnnr": cart.address_line2,
          "toevoeg": ""
      }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((res) => res.json())
      .then((json) => {
        if (json["length"] > 0) {
          cart.address_line1 = json.street;
          setCart( { ...cart, city : json.cityName } )
        }
        else {
          if (json["message"] === '401') {
            setPostnlFails(true);
          }
          setCart( { ...cart, address_line1 : '' } )
          setCart( { ...cart, city : '' } )
        }
      })
    }
    return true;
  }
}

/*const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };*/


  const createOrder = () => {
    var orderMsg = {...cart};
    orderMsg['items'] = items.items;
    orderMsg['smartmeter'] = smartmeterSel;

    if (orderMsg.paymenttype === 'ideal' && orderMsg.issuer === '') {
      setCart({...cart, selecteerBankError:true});
      orderMsg.selecteerBankError = true;
      return;
    }

    fetch('/api/shop/placeOrder', {
      method: 'POST',
      body: JSON.stringify(orderMsg),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((res) => res.json())
    .then((json) => {
      if (json.paymentURL === 'error') {

      }
      else {
        document.location.href = json.paymentURL;
      }
    })
    ;
  }

  const qtyChange = (qty:any, id: number) => {
    fetch('/api/shop/modifyItem', {
     method: 'PATCH',
     body: JSON.stringify({ "productId": id, "id": id, "qty": qty}),
     headers: {
       'Content-Type': 'application/json',
       Accept: 'application/json',
     },
   });
   return;
 }

  const renderProduct = (item: Product, index: number) => {
    const { price, name, id, qty } = item;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl">
          <img
            src={freePNG}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to={`/product-detail-3/${id}`} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to={'/product-detail-3/'+id}>{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                { /*  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{name}</span>
                  </div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

    <span>{`2XL`}</span>*/}

                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber className="relative z-10" defaultValue={qty} onChange={e => qtyChange(e,id)} />
            </div>

            <a
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
            >
              <span>Verwijderen</span>
            </a>
          </div>
        </div>
      </div>
    );
  };


  const renderLeft = () => {
    
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8">
        <div id="ContactInfo" className="scroll-mt-24">
        <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">FACTUURGEGEVENS</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className="font-semibold mt-1 text-sm">
              <span className="">
              </span>
            </div>
          </div>
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6`}
        >
          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Voornaam</Label>
              <Input className="mt-1.5" defaultValue=""
                aria-invalid={errors.first_name ? "true" : "false"}
                {...register("first_name", { required: true })}
                value={cart.first_name}
                onChange={e => { setCart({ ...cart, first_name: e.target.value })}} />
                {errors.first_name && (
                  <span role="alert" className="alert">
                    Verplicht veld
                  </span>
                )}
            </div>
            <div>
              <Label className="text-sm">Achternaam</Label>
              <Input className="mt-1.5" defaultValue=""
              value={cart.last_name}
              {...register("last_name", { required: true })}
              onChange={e => { setCart( { ...cart, last_name : e.target.value } )} } />
              {errors.last_name && (
                  <span role="alert" className="alert">
                    Verplicht veld
                  </span>
                )}
            </div>
          </div>

          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
            <div className="sm:w-1/3">
              <Label className="text-sm">Postcode</Label>
              <Input className="mt-1.5" defaultValue="" 
              value={cart.pincode}
              {...register("pincode", { required: true })}
              onChange={e => { setCart( { ...cart, pincode : e.target.value } )} } 
               />
               {errors.pincode && (
                  <span role="alert" className="alert">
                    Verplicht veld
                  </span>
                )}
            </div>
            <div className="sm:w-1/3">
              <Label className="text-sm">Huisnummer</Label>
              <Input className="mt-1.5" 
              aria-invalid={errors.address_line2 ? "true" : "false"}
              {...register("address_line2", { required: true })}
              value={cart.address_line2}
              onChange={e => { cart.address_line2 = e.target.value; setCart( { ...cart, address_line2 : e.target.value }); checkPostCode();} } 
                />
                {errors.address_line2 && (
                  <span role="alert" className="alert">
                    Verplicht veld
                  </span>
                )}
            </div>
            <div>
              <Label className="text-sm">Toevoeging</Label>
              <Input className="mt-1.5" defaultValue=""
                />
            </div>
          </div>

            {/* ============ */}
          <div className="grid grid-cols-1">
          <div className="flex-1">
              <Label className="text-sm">Straatnaam</Label>
              <Input
                className="mt-1.5 aria-readonly read-only:bg-gray-100"
                placeholder=""
                defaultValue={""}
                readOnly={!(postnlFails)}
                onChange={e => { cart.address_line1 = e.target.value; setCart( { ...cart, address_line1 : e.target.value }); } } 
                type={"text"}
                name="contact[address_line1]"
              value={cart.address_line1}
              
              />
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Plaats</Label>
              <Input className="mt-1.5 aria-readonly read-only:bg-gray-100" 
              defaultValue="" 
              readOnly={!(postnlFails)}
              onChange={e => { cart.city = e.target.value; setCart( { ...cart, city : e.target.value }); } } 
              name="contact[city]"
              value={cart.city}
             
               />
            </div>
            <div>
              <Label className="text-sm">Land</Label>
              <Select className="mt-1.5  aria-readonly read-only:bg-gray-100" defaultValue="Nederland" 
              name="contact[country]"
              value={cart.country}
              onChange={e => { setCart( { ...cart, country : e.target.value } )} }
              >
                <option selected={true} value="Nederland">Nederland</option>
                <option value="Balgie">Belgie</option>
                <option value="Germany">Duitsland</option>
              </Select>
            </div>
          </div>
          <div className="max-w-lg">
            <Label className="text-sm">Emailadres</Label>
            <Input className="mt-1.5" type={"email"}  
                {...register("email_id", { required: true, pattern: /^\S+@\S+\.[A-Za-z]{2,}$/i }) }
                value={cart.email_id}
                aria-invalid={errors.email_id ? "true" : "false"} 
                onChange={e => setCart({ ...cart, email_id: e.target.value })} />
                {errors.email_id && (
                  <span role="alert" className="alert">
                    Incorrect email adres!
                  </span>
                )}
          </div>
          <div className="max-w-lg">
            <Label className="text-sm">Telefoonnummer</Label>
            <Input className="mt-1.5" defaultValue={"+31"} type={"tel"}  name="adres[tel]"
                value={cart.phone}
                onChange={e => setCart({ ...cart, phone: e.target.value })}  />
          </div>

          {/* ============ 
          <div>
            <Label className="text-sm">Address type</Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <Radio
                label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                id="Address-type-home"
                name="Address-type"
                defaultChecked
              />
              <Radio
                label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                id="Address-type-office"
                name="Address-type"
              />
            </div>
          </div>*/}

              </div>
            </div>
          </div>
        </div>
        </form>
    )};

    const verzendLeft = () => {
    
      return (
        <div className="space-y-8 pt-4" >
          <div id="ContactInfo" className="scroll-mt-24">
          <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
          <div className="p-6 flex flex-col sm:flex-row items-start">
            <span className="hidden sm:block">
              <svg
                className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
  
            <div className="sm:ml-8">
              <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                <span className="uppercase">Verzendmethode</span>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2.5"
                  stroke="currentColor"
                  className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              </h3>
              <div className="font-semibold mt-1 text-sm">
                <span className="">
                </span>
              </div>
            </div>
          </div>
          <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6`}
          >
            {/* ============ */}
            <div className="grid grid-cols-1">
              <div>
                <Radio className="mt-1.5"  id="post1" name="post" defaultChecked label="Briefpost (zonder tracking) € 2,95" />
              </div>
              <div>
                <Radio className="mt-1.5" id="post2" name="post" label="PostNL Pakket € 6,95" />
              </div>
            </div>
                </div>
              </div>
            </div>
          </div>
      )};
  
    const verzendSmartMeter = () => {
    
        return (
          <div className="space-y-8 pt-4" >
            <div id="ContactInfo" className="scroll-mt-24">
            <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
            <div className="p-6 flex flex-col sm:flex-row items-start">
              <span className="hidden sm:block">
                <svg
                  className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
    
              <div className="sm:ml-8">
                <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase">Smille Meter</span>
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </h3>
                <div className="font-semibold mt-1 text-sm">
                  <span className="">
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6`}
            >
              {/* ============ */}
              <div className="grid grid-cols-1 onTop">
              <Label className="text-sm">Welke Slimme Meter heeft u thuis? (Optioneel)</Label>
              <Listbox value={smartmeterSel} onChange={setSmartmeterSel}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{smartmeterSel.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {smartmeters.map((smartmeter, personIdx) => (
                <Listbox.Option
                  key={smartmeter.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={smartmeter}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {smartmeter.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
              </div>
                  </div>
                </div>
              </div>
            </div>
        )};

  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Winkelwagentje</title>
      </Helmet>

      <main className="container py-4 lg:pb-4 lg:pt-4 ">
        <div className="mb-4">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
          Bestelling
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Bestelling</span>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()} {verzendSmartMeter()} {verzendLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold">Overzicht</h3>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                { items.items.length === 0 && (
                <div>Winkelmand is leeg!<br/><Link to="/"><ButtonPrimary className="mt-8 w-full">Verder Winkelen</ButtonPrimary></Link></div>
                
              )}
              { items.items.map(renderProduct)}
              {/*[PRODUCTS[0], PRODUCTS[2], PRODUCTS[3]].map(this.renderProduct)*/}
            </div>

            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
              <div>
                <Label className="text-sm">Kortingscode / Kadobon</Label>
                <div className="flex mt-1.5">
                  <Input sizeClass="h-10 px-4 py-3" id="discountCode" className="flex-1" />
                  <button onClick={applyDiscount} className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                    Toepassen
                  </button>
                </div>
                { items.discountText !== '' && (
                    <div className="flex mt-1.5 font-bold text-red-600">
                    {items.discountText} toegepast!
                    </div>
                )}
                
              </div>

              <div className="mt-4 flex justify-between py-2.5">
                <span>Subtotaal</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                €{items.subtotaal}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Verzending</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                €{items.shipping}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>BTW (Alle prijzen zijn inclusief BTW)</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                    (€{items.btw})
                </span>
              </div>
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                <span>Totaal</span>
                <span>€{items.totalPrice}</span>
              </div>
            </div>
            <CheckboxVoor
              className="!text-sm"
              name="voorwaarden"
              label="Ik accepteer de "
              defaultChecked
            /> 
            
             <br/>
             <div>
              <Label className="text-sm">Betaalmethode</Label>
              <Select className="mt-1.5  aria-readonly read-only:bg-gray-100" defaultValue="ideal" 
              value={cart.paymenttype}
              onChange={e => { setCart( { ...cart, paymenttype : e.target.value } )} }
              >
                <option selected={true} value="ideal">iDeal</option>
                <option value="creditcard">Credit Card</option>
              </Select>
            </div>
            <div>
              <Label className="text-sm">Bank</Label>
              <Select className="mt-1.5  aria-readonly read-only:bg-gray-100"
              disabled={cart.paymenttype !== 'ideal'}
              placeholder="Selecteer je bank"
              onChange={e => { setCart( { ...cart, issuer : e.target.value } )} }
              >
                <option value="">Selecteer je bank</option>
                <option value="ideal_ABNANL2A">ABN AMRO</option>
                <option value="ideal_INGBNL2A">ING</option>
                <option value="ideal_RABONL2U">Rabobank</option>
                <option value="ideal_ASNBNL21">ASN Bank</option>
                <option value="ideal_BUNQNL2A">bunq</option>
                <option value="ideal_KNABNL2H">Knab</option>
                <option value="ideal_RBRBNL21">Regiobank</option>
                <option value="ideal_REVOLT21">Revolut</option>
                <option value="ideal_SNSBNL2A">SNS Bank</option>
                <option value="ideal_TRIONL2U">Triodos</option>
                <option value="ideal_FVLBNL22">Van Lanschot</option>
              </Select>
              {cart.selecteerBankError && (
                  <span role="alert" className="alert">
                    Verplicht veld
                  </span>
                )}
            </div>
            <ButtonPrimary className="mt-8 w-full disabled" onClick={handleSubmit(createOrder)} disabled>Bestelling plaatsen (Tijdelijk gesloten voor particulieren)</ButtonPrimary>
            <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
              <p className="block relative pl-5">
                {/*<svg
                  className="w-4 h-4 absolute -left-1 top-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8V13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9945 16H12.0035"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Learn more{` `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Taxes
                </a>
                <span>
                  {` `}and{` `}
                </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Shipping
                </a>
                 {` `} infomation */}
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
  
};

//export default CheckoutPage;
