import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import NotifyMessage from "components/NotifyMessage";

export interface PageLoginProps {
  className?: string;
}

const loginSocials: any[] = [
];

/*const loginSocialsSoon = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];*/


const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const history = useHistory();

  const handleSubmit = (event:any) => {
    // Prevent page reload
    event.preventDefault();
  
    var { email, pass } = document.forms[0];
  
    loginUser(email.value, pass.value);
  };

  async function loginUser(email:string, pass:string) {
    const user = {email:email,pass:pass};
    return fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(data => data.json())
      .then((result) => {
        if(result.message === "Logged In"){
          history.push('/account')
         } else {
          toast.custom(
            (t) => (
              <NotifyMessage
                message="Username and/or password are invalid!"
                show={t.visible}
                subject="Username and/or password are invalid!"
                type="error"
              />
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          
         }
      });
   }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          
          { loginSocials.length > 0 &&
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              Of
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          }
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Emailadres
              </span>
              <Input
                type="email"
                name="email" required
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Wachtwoord
                <Link to="/forgot-pass" className="text-sm text-green-600">
                  Wachtwoord vergeten?
                </Link>
              </span>
              <Input type="password" name="pass" required className="mt-1" />
            </label>
            <ButtonPrimary type="submit">Inloggen</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Nieuw? {` `}
            <Link className="text-green-600" to="/signup">
               Registreren
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
