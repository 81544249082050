import Logo from "shared/Logo/Logo";
//import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { Link } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Over onze",
    menus: [
      { href: "/contact", label: "Contact", targetBlank: false },
      { href: "/algemenevoorwaarden", label: "Algemene voorwaarden", targetBlank: false },
      { href: "/privacy", label: "Privacy", targetBlank: false },
      { href: "/vraagenantwoord", label: "Veel gestelde vragen", targetBlank: false },
      { href: "/contact", label: "Klantenservice", targetBlank: false },
    ],
  },
  {
    id: "1",
    title: "Producten",
    menus: [
      { href: "/", label: "Free Energy Puck" },
    ],
  }
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-2">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                to={item.href}
                rel="noopener noreferrer"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-8 lg:pt-8 lg:pb-4 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <div className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start text-base text-xs" >
              © 2000 - 2023 - CEON Business Systems B.V.<br/>
              Handelsnaam: Free Energy<br/>
              KvK 12556572<br/>
              </div>
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <div className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start text-base text-xs" >
              <Link to="/about" className={`ttnc-logo inline-block text-slate-600 `} >Duurzaam ondernemen</Link>
              <Link to="/about" >CO2-neutraal <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAYCAMAAAAiV0Z6AAAAPFBMVEVLoEN0wU6CzFKCzFKCzFKCzFKCzFJSo0MSczNDmkCCzFJPoUMTczNdr0gmgziCzFITczMTczMTczMTczPh00jOAAAAFHRSTlPF/+bIsms8Ad///hX+//5/tXw7aMEAx10AAACaSURBVHgBbc4HDoRQCATQ33tbvf9dF9QxaCT9UQaltLHOh/golXKhMs5Xqa0xU1lyoa2fXFyQOsDG38qsLy4TaV+sFislovyhPzLJJrBu6eQOtpW0LjbJkzTuTDLRVNKa3uxJI+VdiRqXSeu6GW+Qxi29eLIi8H7EsYrT42BD+mQtNO5JMjRuC4lSY8V4hsLX0egGijvUSEP9AbylEsOkeCgWAAAAAElFTkSuQmCC" alt="" className="footerImg" data-atf="1" data-frt="0" /></Link>
              </div>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
