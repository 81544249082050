import React, { FC } from "react";
import { Helmet } from "react-helmet";

export interface PageContactProps {
  className?: string;
}

const PagePrivacy: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Privacy</title>
      </Helmet>
      <div className="">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Privacy
        </h2>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative">
          <div className="marginP">
          <p>Privacybeleid Free<br /><br />
https://www.free-e.nl<br /><br />
Over ons privacybeleid<br /><br />
Free geeft veel om uw privacy. Wij verwerken daarom uitsluitend gegevens die wij nodig hebben voor (het<br />
verbeteren van) onze dienstverlening en gaan zorgvuldig om met de informatie die wij over u en uw gebruik van<br />
onze diensten hebben verzameld. Wij stellen uw gegevens nooit voor commerci&euml;le doelstellingen ter beschikking aan<br />
derden.<br />
Dit privacybeleid is van toepassing op het gebruik van de website en de daarop ontsloten dienstverlening van Free.<br />
De ingangsdatum voor de geldigheid van deze voorwaarden is 31/10/2022, met het publiceren van een nieuwe versie<br />
vervalt de geldigheid van alle voorgaande versies. Dit privacybeleid beschrijft welke gegevens over u door ons<br />
worden verzameld, waar deze gegevens voor worden gebruikt en met wie en onder welke voorwaarden deze<br />
gegevens eventueel met derden kunnen worden gedeeld. Ook leggen wij aan u uit op welke wijze wij uw gegevens<br />
opslaan en hoe wij uw gegevens tegen misbruik beschermen en welke rechten u heeft met betrekking tot de door u<br />
aan ons verstrekte persoonsgegevens.<br />
Als u vragen heeft over ons privacybeleid kunt u contact opnemen met onze contactpersoon voor privacyzaken, u<br />
vindt de contactgegevens aan het einde van ons privacybeleid.<br />
Over de gegevensverwerking<br />
Hieronder kan u lezen op welke wijze wij uw gegevens verwerken, waar wij deze (laten) opslaan, welke<br />
beveiligingstechnieken wij gebruiken en voor wie de gegevens inzichtelijk zijn.<br />
Webwinkelsoftware<br />
MijnWebwinkel<br />
Onze webwinkel is ontwikkeld met software van MyOnlineStore. Persoonsgegevens die u ten behoeve van onze<br />
dienstverlening aan ons beschikbaar stelt, worden met deze partij gedeeld. MyOnlineStore heeft toegang tot uw<br />
gegevens om ons (technische) ondersteuning te bieden, zij zullen uw gegevens nooit gebruiken voor een ander<br />
doel. MyOnlineStore is op basis van de overeenkomst die wij met hen hebben gesloten verplicht om passende<br />
beveiligingsmaatregelen te nemen. MyOnlineStore maakt gebruik van cookies om technische informatie te<br />
verzamelen met betrekking tot uw gebruik van de software, er worden geen persoonsgegevens verzameld en/of<br />
opgeslagen.<br />
<br />
Digital Ocean<br />
Wij maken voor ons reguliere zakelijke e-mailverkeer gebruik van de diensten van Digital Ocean. Deze partij heeft<br />
passende technische en organisatorische maatregelen getroffen om misbruik, verlies en corruptie van uw en onze<br />
gegevens zoveel mogelijk te voorkomen. Digital Ocean heeft geen toegang tot ons postvak en wij behandelen al<br />
ons e-mailverkeer vertrouwelijk.<br />
Payment processors<br />
<br />
Mollie<br />
Voor het afhandelen van een (deel van) de betalingen in onze webwinkel maken wij gebruik van het platform van<br />
Mollie. Mollie verwerkt uw naam, adres en woonplaatsgegevens en uw betaalgegevens zoals uw bankrekening- of<br />
creditcardnummer. Mollie heeft passende technische en organisatorische maatregelen genomen om uw<br />
persoonsgegevens te beschermen. Mollie behoudt zich het recht voor uw gegevens te gebruiken om de<br />
dienstverlening verder te verbeteren en in het kader daarvan (geanonimiseerde) gegevens met derden te delen.<br />
Alle hierboven genoemde waarborgen met betrekking tot de bescherming van uw persoonsgegevens zijn eveneens<br />
van toepassing op de onderdelen van Mollie&rsquo;s dienstverlening waarvoor zij derden inschakelen. Mollie bewaart uw<br />
gegevens niet langer dan op grond van de wettelijke termijnen is toegestaan.<br />
Beoordelingen<br />
<br />
Verzenden en logistiek<br />
<br />
PostNL<br />
Als u een bestelling bij ons plaatst is het onze taak om uw pakket bij u te laten bezorgen. Wij maken gebruik van<br />
de diensten van PostNL voor het uitvoeren van de leveringen. Het is daarvoor noodzakelijk dat wij uw naam,<br />
adres en woonplaatsgegevens met PostNL delen. PostNL gebruikt deze gegevens alleen ten behoeve van het<br />
uitvoeren van de overeenkomst. In het geval dat PostNL onderaannemers inschakelt, stelt PostNL uw gegevens<br />
ook aan deze partijen ter beschikking.<br />
Facturatie en boekhouden<br />
<br />
Externe verkoopkanalen<br />
<br />
Bol.com<br />
Wij verkopen (een deel van) onze artikelen via het platform van Bol.com. Als u via dit platform een bestelling<br />
plaatst dan deelt Bol.com uw bestel- en persoonsgegevens met ons. Wij gebruiken deze gegevens om uw<br />
bestelling af te handelen. Wij gaan vertrouwelijk met uw gegevens om en hebben passende technische en<br />
organisatorische maatregelen getroffen om uw gegevens te beschermen tegen verlies en ongeoorloofd gebruik.<br />
Marktplaats.nl<br />
Wij verkopen (een deel van) onze artikelen via het platform van Marktplaats.nl. Als u via dit platform een<br />
bestelling plaatst dan deelt Marktplaats.nl uw bestel- en persoonsgegevens met ons. Wij gebruiken deze gegevens<br />
om uw bestelling af te handelen. Wij gaan vertrouwelijk met uw gegevens om en hebben passende technische en<br />
organisatorische maatregelen getroffen om uw gegevens te beschermen tegen verlies en ongeoorloofd gebruik.<br />
Doel van de gegevensverwerking<br />
Algemeen doel van de verwerking<br />
Wij gebruiken uw gegevens uitsluitend ten behoeve van onze dienstverlening. Dat wil zeggen dat het doel van de<br />
verwerking altijd direct verband houdt met de opdracht die u verstrekt. Wij gebruiken uw gegevens niet voor<br />
(gerichte) marketing. Als u gegevens met ons deelt en wij gebruiken deze gegevens om - anders dan op uw<br />
verzoek - op een later moment contact met u op te nemen, vragen wij u hiervoor expliciet toestemming. Uw<br />
gegevens worden niet met derden gedeeld, anders dan om aan boekhoudkundige en overige administratieve<br />
verplichtingen te voldoen. Deze derden zijn allemaal tot geheimhouding gehouden op grond van de overeenkomst<br />
tussen hen en ons of een eed of wettelijke verplichting.<br />
Automatisch verzamelde gegevens<br />
Gegevens die automatisch worden verzameld door onze website worden verwerkt met het doel onze<br />
dienstverlening verder te verbeteren. Deze gegevens (bijvoorbeeld uw IP-adres, webbrowser en<br />
besturingssysteem) zijn geen persoonsgegevens.<br />
Medewerking aan fiscaal en strafrechtelijk onderzoek<br />
In voorkomende gevallen kan Free op grond van een wettelijke verplichting worden gehouden tot het delen van<br />
uw gegevens in verband met fiscaal of strafrechtelijk onderzoek van overheidswege. In een dergelijk geval zijn wij<br />
gedwongen uw gegevens te delen, maar wij zullen ons binnen de mogelijkheden die de wet ons biedt daartegen<br />
verzetten.<br />
<br />
Bewaartermijnen<br />
Wij bewaren uw gegevens zolang u cli&euml;nt van ons bent. Dit betekent dat wij uw klantprofiel bewaren totdat u<br />
aangeeft dat u niet langer van onze diensten gebruik wenst te maken. Als u dit bij ons aangeeft zullen wij dit<br />
tevens opvatten als een vergeetverzoek. Op grond van toepasselijke administratieve verplichtingen dienen wij<br />
facturen met uw (persoons)gegevens te bewaren, deze gegevens zullen wij dus voor zolang de toepasselijke<br />
termijn loopt bewaren. Medewerkers hebben echter geen toegang meer tot uw cli&euml;ntprofiel en documenten die<br />
wij naar aanleiding van uw opdracht hebben vervaardigd.<br />
<br />
Uw rechten<br />
Op grond van de geldende Nederlandse en Europese wetgeving heeft u als betrokkene bepaalde rechten met<br />
betrekking tot de persoonsgegevens die door of namens ons worden verwerkt. Wij leggen u hieronder uit welke<br />
rechten dit zijn en hoe u zich op deze rechten kunt beroepen. In beginsel sturen wij om misbruik te voorkomen<br />
afschriften en kopie&euml;n van uw gegevens enkel naar uw bij ons reeds bekende e-mailadres. In het geval dat u de<br />
gegevens op een ander e-mailadres of bijvoorbeeld per post wenst te ontvangen, zullen wij u vragen zich te<br />
legitimeren. Wij houden een administratie bij van afgehandelde verzoeken, in het geval van een vergeetverzoek<br />
administreren wij geanonimiseerde gegevens. Alle afschriften en kopie&euml;n van gegevens ontvangt u in de<br />
machineleesbare gegevensindeling die wij binnen onze systemen hanteren. U heeft te allen tijde het recht om een<br />
klacht in te dienen bij de Autoriteit Persoonsgegevens als u vermoedt dat wij uw persoonsgegevens op een<br />
verkeerde manier gebruiken.<br />
<br />
Inzagerecht<br />
U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw persoon of<br />
daartoe herleidbaar zijn, in te zien. U kunt een verzoek met die strekking doen aan onze contactpersoon voor<br />
privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd<br />
sturen wij u op het bij ons bekende e-mailadres een kopie van alle gegevens met een overzicht van de verwerkers<br />
die deze gegevens onder zich hebben, onder vermelding van de categorie waaronder wij deze gegevens hebben<br />
opgeslagen.<br />
<br />
Rectificatierecht<br />
<br />
U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw persoon of<br />
daartoe herleidbaar zijn, te laten aanpassen. U kunt een verzoek met die strekking doen aan onze contactpersoon<br />
voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd<br />
sturen wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens zijn aangepast.<br />
Recht op beperking van de verwerking<br />
U heeft altijd het recht om de gegevens die wij (laten) verwerken die betrekking hebben op uw persoon of daartoe<br />
herleidbaar zijn, te beperken. U kunt een verzoek met die strekking doen aan onze contactpersoon voor<br />
privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd<br />
sturen wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens tot u de beperking opheft niet<br />
langer worden verwerkt.<br />
<br />
Recht op overdraagbaarheid<br />
<br />
U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw persoon of<br />
daartoe herleidbaar zijn, door een andere partij te laten uitvoeren. U kunt een verzoek met die strekking doen aan<br />
onze contactpersoon voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw<br />
verzoek wordt ingewilligd sturen wij u op het bij ons bekende e-mailadres afschriften of kopie&euml;n van alle gegevens<br />
over u die wij hebben verwerkt of in opdracht van ons door andere verwerkers of derden zijn verwerkt. Naar alle<br />
waarschijnlijkheid kunnen wij in een dergelijk geval de dienstverlening niet langer voortzetten, omdat de veilige<br />
koppeling van databestanden dan niet langer kan worden gegarandeerd.<br />
Recht van bezwaar en overige rechten<br />
U heeft in voorkomende gevallen het recht bezwaar te maken tegen de verwerking van uw persoonsgegevens<br />
door of in opdracht van Free. Als u bezwaar maakt zullen wij onmiddellijk de gegevensverwerking staken in<br />
afwachting van de afhandeling van uw bezwaar. Is uw bezwaar gegrond dat zullen wij afschriften en/of kopie&euml;n<br />
van gegevens die wij (laten) verwerken aan u ter beschikking stellen en daarna de verwerking blijvend staken. U<br />
heeft bovendien het recht om niet aan geautomatiseerde individuele besluitvorming of profiling te worden<br />
onderworpen. Wij verwerken uw gegevens niet op zodanige wijze dat dit recht van toepassing is. Bent u van<br />
mening dat dit wel zo is, neem dan contact op met onze contactpersoon voor privacyzaken.<br />
<br />
Cookies<br />
<br />
Google Analytics<br />
Via onze website worden cookies geplaatst van het Amerikaanse bedrijf Google, als deel van de &ldquo;Analytics&rdquo;-dienst.<br />
Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken.<br />
Deze verwerker is mogelijk verplicht op grond van geldende wet- en regelgeving inzage te geven in deze<br />
gegevens. Wij hebben Google niet toegestaan de verkregen analytics-informatie te gebruiken voor andere Google<br />
diensten.<br />
<br />
Cookies van derde partijen<br />
<br />
In het geval dat softwareoplossingen van derde partijen gebruik maken van cookies is dit vermeld in deze<br />
privacyverklaring.<br /><br />
Wijzigingen in het privacybeleid<br />
<br />
Wij behouden te allen tijde het recht ons privacybeleid te wijzigen. Op deze pagina vindt u echter altijd de meest<br />
recente versie. Als het nieuwe privacybeleid gevolgen heeft voor de wijze waarop wij reeds verzamelde gegevens<br />
met betrekking tot u verwerken, dan brengen wij u daarvan per e-mail op de hoogte.<br />
Contactgegevens<br /><br />
Free Energy (handelsnaam van CEON Business Systems B.V.)<br />
T (06) 83 50 65 72 E klantenservice@free-e.nl<br />
<br />
</p></div>

    </div>  
      </div>
    </div>
  );
};

export default PagePrivacy;
