import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "../containers/PageHome/PageHome";
import Page404 from "../containers/Page404/Page404";
import AccountPage from "../containers/AccountPage/AccountPage";
import PageContact from "../containers/PageContact/PageContact";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageSignUp from "../containers/PageSignUp/PageSignUp";
import PageLogin from "../containers/PageLogin/PageLogin";
import PageSubcription from "../containers/PageSubcription/PageSubcription";
import BlogPage from "../containers/BlogPage/BlogPage";
import BlogSingle from "../containers/BlogPage/BlogSingle";
import SiteHeader from "../containers/SiteHeader";
import PageCollection from "../containers/PageCollection";
import PageSearch from "../containers/PageSearch";
import ProductDetailPage from "../containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "../containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "../containers/AccountPage/AccountSavelists";
import AccountPass from "../containers/AccountPage/AccountPass";
import AccountBilling from "../containers/AccountPage/AccountBilling";
import AccountOrder from "../containers/AccountPage/AccountOrder";
import CartPage from "../containers/ProductDetailPage/CartPage";
import CheckoutPage from "../containers/PageCheckout/CheckoutPage";
import PageCollection2 from "../containers/PageCollection2";
import PageHomeSoon from "../containers/PageHome/PageHomeSoon";
import PageAlgemeneVoorwaarden from "../containers/PageContact/PageAlgemeneVoorwaarden";
import PagePrivacy from "../containers/PageContact/PagePrivacy";
import PageRetour from "../containers/PageContact/PageRetour";
import PageUnsubscribe from "../containers/PageContact/PageUnsubscribe";
import ProductDetailPage3 from "../containers/ProductDetailPage/ProductDetailPage3";
import CheckoutConfirmPage from "../containers/PageCheckout/CheckoutConfirmPage";
import PageHomeZakelijk from "containers/PageHome/PageHomeZakelijk";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHomeSoon },
  { path: "/#", exact: true, component: PageHomeSoon },
  { path: "/zakelijk", exact: true, component: PageHomeZakelijk },
  /*{ path: "/home1", exact: true, component: PageHome },
  { path: "/home2", exact: true, component: PageHome2 },
  { path: "/home3", exact: true, component: PageHome3 },*/
  //
  { path: "/home-header-2", exact: true, component: PageHome },
  { path: "/product-detail", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  //{ path: "/product-detail-3", component: ProductDetailPage3 },
  { path: "/product-detail-3/:productId", component: ProductDetailPage3 },
  { path: "/product/:productId", component: ProductDetailPage3 },
  //
  { path: "/page-collection-2", component: PageCollection2 },
  { path: "/page-collection", component: PageCollection },
  { path: "/products", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  //
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  //
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/confirm/:orderNr", component: CheckoutConfirmPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },

  { path: "/algemenevoorwaarden", component: PageAlgemeneVoorwaarden },
  { path: "/privacy", component: PagePrivacy },
  { path: "/unsubscribe", component: PageUnsubscribe },
  { path: "/vraagenantwoord", component: PageRetour },
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
