import React from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import line1 from "images/st/line1.svg";
import promo5 from "images/products/mainP1img.png";

function PageHomeZakelijk() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Free Energy - CEON</title>
      </Helmet>

      <BgGlassmorphism />

        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">

          <div className={`nc-SectionHero relative`} data-nc-id="SectionHero" >
            <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
              <div className="flex-1 w-120 lg:w-64">
                <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                Uw energie kosten <span className="headingHigh1">transparant</span> en <span className="headingHigh1">eerlijk</span>
                <img src={line1} alt="" className="cs-screen"></img>
                </h2>
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 pb-6">
                We worden allemaal energieondernemer. Uw heeft vast al in uw bedrijf electrische auto's en/of zonnepanelen en/of eenergieopslag (zoals batterijen). Als uw slim met uw energie vraagstuk omgaat liggen er vele kansen voor uw onderneming. Wat als uw de bedrijfsprocesses automatisch laat aanpassen op basis van de energieprijzen bijvroobeeld. We praten graag met u hierover mee.
                </span>
                <ButtonPrimary href="/contact">Neem contact op</ButtonPrimary>
              </div>
              <div className="flex-1 w-120 lg:w-64">
                <img className="w-full rounded-lg" src={promo5} alt="" />
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default PageHomeZakelijk;
