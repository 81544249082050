import React from "react";
import { Helmet } from "react-helmet";
//import Pagination from "shared/Pagination/Pagination";
//import ButtonPrimary from "shared/Button/ButtonPrimary";
//import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import TabFilters from "./TabFilters";
import { Product } from "data/data";

export interface PageCollectionProps {
  className?: string;
  loading?: boolean;
}

class PageCollection extends React.Component<{className?: string;}, {loading:boolean, products:any}> {

  // Constructor 
  constructor(props:any) {
    super(props);

    this.state = {
      loading: true,
      products: []
    };
}
//const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {

  async useApiGet (url: string)  {
    this.setState({loading: true});
    try {
      const apiResponse = await fetch(url);
      const json = await apiResponse.json();
      this.setState({loading: false});
      return json;
    } catch (error) {
      this.setState({loading: false});
      return {};
    }
    
  }

  async componentDidMount() {
    const products = await this.useApiGet('/api/shop/products/main');
    this.setState({products: products});
  }
  
 render(): React.ReactNode {
  return (
    <div
      className={`nc-PageCollection ${this.props.className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Products</title>
      </Helmet>
      
      <div className="container py-5 lg:pb-15 lg:pt-10 space-y-6 sm:space-y-10 lg:space-y-10">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-md">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Web Shop
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              Wij helpen u graag om het juiste product voor u te vinden. <br/>Wij zijn makkelijk te benaderen via the chat optie rechts onder!
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* TABS FILTER */}
            <TabFilters />

            { this.state.loading === false && (

              <><div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                {this.state.products.map((item: Product | undefined, index: React.Key | null | undefined) => (
                  <ProductCard data={item} key={index} />
                ))}
              </div>{/*<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                  <Pagination />
                  <ButtonPrimary loading>Show me more</ButtonPrimary>
                </div>*/}</>

            )}
          </main>
        </div>

        {/* === SECTION 5 === 
        <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderCollections />
        <hr className="border-slate-200 dark:border-slate-700" />*/}

        {/* SUBCRIBES */}
        <SectionPromo1 />
      </div>
    </div>
  );

 }
};

export default PageCollection;
