import React, { FC } from "react";
import { Helmet } from "react-helmet";
/*import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";*/

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "Wat is mijn Herroepingsrecht?",
    desc: "U heeft het recht uw bestelling tot 14 dagen na ontvangst zonder opgave van reden te annuleren. U heeft na annulering nogmaals 14 dagen om uw product retour te sturen. U krijgt dan het volledige orderbedrag inclusief verzendkosten gecrediteerd. Enkel de kosten voor retour van u thuis naar de webwinkel zijn voor eigen rekening. Deze kosten bedragen circa 7,25 per pakket, raadpleeg voor de exacte tarieven de website van uw vervoerder. Indien u gebruik maakt van uw herroepingsrecht, zal het product met alle geleverde toebehoren en – indien redelijkerwijze mogelijk – in de originele staat en verpakking aan de ondernemer geretourneerd worden. Om gebruik te maken van dit recht kunt u contact met ons opnemen via klantenservice@free-e.nl. Wij zullen vervolgens het verschuldigde orderbedrag binnen 14 dagen na aanmelding van uw retour terugstorten mits het product reeds in goede orde retour ontvangen is."
  },
  {
    title: "Wat zijn de verzend mogelijkheden?",
    desc: "Bezorging zal plaatsvinden via PostNL (of wanneer u tijdens het bestellen voor een andere pakketbezorger heeft geselecteerd)"
  },
  {
    title: "Levertijd en verzendkosten?",
    desc: "Is het product op voorraad dan geld op werkdagen dat de levertijd de volgende dag is. Tijdens de bestelling plaatsen wordt de verwachte levertijd en verzendkosten vermeld"
  },
  {
    title: "Retour",
    desc: "Wil je een product/bestelling retour sturen volgens het herroepingsrecht stuur ons dan een email voor instructies om het product retour te sturen."
  },
  {
    title: "Wat zijn de betaalmethodes?",
    desc: "Op dit moment is aleen bank transactie via iDeal mogelijk"
  },
];

const PageRetour: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Veel gestelde vragen</title>
      </Helmet>
      <div className="">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Veel gestelde vragen
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-1 gap-12 ">
            <div>
            </div>
            <div className="space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
                <br/> 
    </div>
  );
};

export default PageRetour;
