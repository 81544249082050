import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";



import appIosBadge from "images/st/app-store-badge.svg";
import playBadge from "images/st/google-play-badge.svg";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
  right: boolean;
  appbadges?: boolean;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
  right,
  appbadges
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
       {right === false && <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>}
        <div className="w-screen space-y-5 lg:space-y-7 flex-grow">
          <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h1>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          {!!btnText && <ButtonPrimary href="/checkout">{btnText}</ButtonPrimary>}
          {!!appbadges && <><div className="flex align-items-center"><div className="flex-auto w-32">
          <a href="https://apps.apple.com/nl/app/free-energy/id1668108171" target="_blank" rel="noreferrer">
                <img
            src={appIosBadge}
            alt="App"
            className="app-badge"
          ></img></a></div><div className="flex-auto w-32"><a href="https://play.google.com/store/apps/details?id=com.ceon.freeenergy&pli=1"><img
            src={playBadge}
            alt="Google Play"
            className="app-badge"
          ></img></a></div><div className="flex-auto w-32">
          <a href="https://dash.free-e.nl"><ButtonPrimary className="rounded-lg app-badge" >Web Laptop/Pc/Mac</ButtonPrimary></a></div>
          </div></>}
        </div>
        {right === true && <div className="flex flex-col">
          <img className="w-full rounded-lg" src={rightImg} alt="" />
        </div>}
      </div>
    </div>
  );
};

export default SectionHero;
