import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
//import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
//import BackgroundSection from "components/BackgroundSection/BackgroundSection";
//import SectionPromo1 from "components/SectionPromo1";
//import { stringify } from "querystring";
import toast from "react-hot-toast";
import NotifyMessage from "components/NotifyMessage";
import { useHistory } from "react-router-dom";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "EMAIL",
    desc: "klantenservice@free-e.nl",
  },
  {
    title: "Telefoon",
    desc: "06-83 50 65 72",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const history = useHistory();
  const [message, setMessage] = useState({subject:'',message:'',email:'',name:''});

  const submitMessage = () => {  
    fetch('/api/shop/contactUsMessage', {
      method: 'POST',
      body: JSON.stringify(message),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(json => {
        toast.custom(
          (t) => (
            <NotifyMessage
              show={true}
              subject="Bedankt voor u bericht!"
              message={`Ticket: ${json.ticket}`}
              type="info"
            />
          ),
          { position: "top-right", id: "nc-product-notify", duration: 4000 }
        );
        history.push('/');
      })
  }

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
        Stuur een bericht naar onze klantenservice en wij zulle u zo snel mogelijken beantwoorden.<br/>
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-1 gap-12 ">
            <div>
              
                <div className="space-y-2 pt-4">
                <label className="block">
                  <Label>Naam</Label>

                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1" name="message[name]"
                    value={message.name}
                    onChange={e => setMessage({ ...message, name: e.target.value })}
                  />
                </label>
                </div>
                <div className="space-y-2 pt-4">
                <label className="block">
                  <Label>Emailadres</Label>

                  <Input
                    type="email"
                    placeholder="email@adres.nl"
                    className="mt-1" name="message[email]"
                    value={message.email}
                    onChange={e => setMessage({ ...message, email: e.target.value })}
                  />
                </label>
                </div>
                <div className="space-y-2 pt-4">
                <label className="block">
                  <Label>Bericht</Label>

                  <Textarea className="mt-1" rows={6}  name="message[message]"
                    value={message.message}
                    onChange={e => setMessage({ ...message, message: e.target.value })} />
                </label>
                </div>
                <div className="space-y-2 pt-4">
                  <ButtonPrimary onClick={submitMessage}>Verstuur Bericht</ButtonPrimary>
                </div>
            </div>
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
                <br/> 
    </div>
  );
};

export default PageContact;
